<template>
  <div class="text-container">
    <p>
      Bei Ihnen kommt es kurzfristig zu einem personellen Engpass in Ihrer
      Apotheke und Sie brauchen eine qualifizierte Vertretung? Wir übernehmen
      unkompliziert die Vertretung für Apotheken im Großraum Hamm, Ahlen, Soest
      und Dortmund.
    </p>
    <p>
      Gerne übernehme ich auch persönlich die Vertretung in Ihrer Apotheke.
      Zuletzt war ich selbstständig mit drei Apotheken und einem Sanitätshaus.
      Ich unterstütze Sie, Ihr Team und Ihre Kunden mit meinen über 45 Jahren
      Erfahrung als Apothekerin.
    </p>
    <p>
      Schreiben Sie mir einfach eine Nachricht über das Kontaktformular oder
      schicken Sie mir eine E-Mail an
      <a href="mailto:wigand@awh-beratung.de">wigand@awh-beratung.de</a>.
    </p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir helfen bei Personalengpässen und übernehmen die Vertretung von Apothekerinnen und Apothekern.",
      },
    ],
  },
};
</script>